.phone-number-detail {
  .cost-type {
    border-radius: 0.875em;
    padding-top: 0.4em;
    padding-bottom: 0.25em;

    &.free {
      border: 1px solid $green;
      color: $green;
      background-color: rgba($green, 0.1);
    }

    &.gold {
      border: 1px solid $light-yellow;
      color: $light-yellow;
      background-color: rgba($light-yellow, 0.1);
    }
  }
}
