.side-menu {
  .language-toggler-btn {
    padding: 0.4em;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:focus {
      border: 0;
      box-shadow: none;
    }

    p {
      font-size: 0.8em;
    }

    svg {
      font-size: 1.2em;
      color: $gray-500;
      margin-right: 0.5em;
    }

    .caret-icon {
      font-size: 0.9em;
      margin-left: 0.2em;
    }
  }

  .language-toggler {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    border-top: 0.0625rem solid $blue-700;
  }

  .language-dropdown {
    min-width: 5em;

    button {
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      font-size: 0.8em;
      font-weight: 500;
      text-align: center;
    }
  }

  &.sidebar-minimized {
    .language-toggler {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    .language-toggler-btn {
      background: none;
      border: none;
      padding: 0 3em;
      cursor: pointer;
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 0.7em;
        font-weight: 500;
      }

      svg {
        font-size: 1.2em;
        color: $gray-500;
        margin: 0;
      }

      .caret-icon {
        font-size: 0.9em;
        margin: 0;
      }
    }

    .language-dropdown {
      /* These !important statement is used to override the initial implementation from Reactstrap library for dropdown according to our UI requirements */
      min-width: 80px !important; // to update initial minimum width
      transform: translate3d(
        -4px,
        -89px,
        0
      ) !important; // to update initial transform property according to our requirement
      inset: 0 auto auto 0 !important; // to update default position properties

      button {
        font-size: 0.8em;
        font-weight: 500;
        text-align: center;

        &:hover {
          color: $gray-800;
        }
      }
    }

    .sidebar-language-toggler {
      @include min-break-point(tablet) {
        position: fixed;
        bottom: 1.5%;
        left: 9%;
        z-index: 100;
      }

      @include min-break-point(large-desktop) {
        bottom: 3.5%;
        left: 6.8%;
      }

      .dropdown-menu {
        &::after {
          content: "";
          width: 0;
          height: 0;
          display: block;
          position: absolute;
          z-index: 10;
          border: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          bottom: 16%;
          border-right: 10px solid $white;
          left: -10px;
        }
      }
    }
  }
}
