.add-on-subscription {
  &-title {
    font-size: $h4-font-size;
    color: $gray-800;
    font-family: $font-family-heading;
  }

  &-container {
    min-width: 50em;
  }

  .subscription-detail {
    background-color: rgba($mild-gray, 0.4);
    outline: none;
    box-shadow: none;
    color: $gray-800;

    &.add-on-detail {
      color: $gray-800;
      background-color: $mild-gray;
    }

    &:after {
      @extend .d-none;
    }
  }

  .table-body {
    min-height: 9em;
  }

  &-empty-text {
    color: $gray-700;
    font-size: $h5-font-size;
  }

  &-field {
    min-width: 16em;
  }

  .delete-button {
    @include size(2.5em);
  }

  &-row {
    border-bottom: 0.0625em solid $gray-400;
    min-height: 5em;

    .editable-product-name-with-label {
      @include max-break-point(tablet-landscape) {
        flex-direction: column-reverse;
        gap: 0.25em;
        // to override the equivalent bootstrap class behaviour
        align-items: flex-start !important;
      }
    }
  }

  p {
    font-size: $h5-font-size;
    color: $gray-800;
  }

  .total-calculation {
    p {
      color: $gray-800;
    }
  }

  .setup {
    border-radius: 0.875em;
    padding-top: 0.4em;
    padding-bottom: 0.25em;

    border: 1px solid $green;
    color: $green;
    background-color: rgba($green, 0.1);
  }

  &-footer {
    border: 0.0625em solid $gray-400;

    @include max-break-point(mobile-phone) {
      gap: 0.7em;
      flex-direction: column;

      .add-on-subscription-field {
        min-width: 13em;

        // to override the left margin applied by bootstrap class
        margin-left: 0 !important;
      }
    }
  }

  tbody {
    border-inline: 0.0625em solid $gray-400;
  }
}
