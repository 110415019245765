.loading-indicator {
  display: flex;
  align-items: center;
  .dot {
    width: 0.25em;
    height: 0.25em;
    margin-right: 0.25em;
    animation: dots 1s ease-in-out 0ms infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    vertical-align: top;

    &:nth-of-type(2) {
      animation: dots 1s ease-in-out 160ms infinite;
    }

    &:nth-of-type(3) {
      animation: dots 1s ease-in-out 320ms infinite;
    }
  }
}
