.assign-sim-card-form {
  .assign-sim-card-type,
  .sim-card-holder {
    background-color: $gray-100;
    border: 0.0625em solid $gray-400;
    min-height: 3em;
    border-radius: 0.25em;
    color: $gray-700;
    min-width: 8.125em;
    cursor: pointer;
    width: 100%;

    @include min-break-point(tablet) {
      width: auto;
    }

    label,
    input {
      cursor: pointer;
    }

    &.active {
      background-color: rgba($green, 0.1);
      border: 0.0625em solid $green;
    }
  }
}

.card-holder {
  border-bottom: 0.0625em solid $gray-200;
}
