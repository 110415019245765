.debtor-detail {
  .profile-detail {
    background-color: $white;

    .avatar-container {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      .avatar {
        position: relative;

        .icon {
          position: relative;
          height: 3.5em;
          width: 3.5em;
          border-radius: 50%;
          background-color: $tertiary;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          font-weight: 600;
          color: $white;
          border: 0.1em solid $gray-200;
          font-size: 3em;
          box-shadow: 0 1px 1px rgba($color: $black, $alpha: 0.1);
        }

        // .active-indicator {
        //   height: 0.45em;
        //   width: 0.45em;
        //   border-radius: 50%;
        //   background-color: $primary;
        //   border: 0.05em solid $white;
        //   position: absolute;
        //   right: -0.05em;
        //   top: 70%;
        // }
      }

      .customer-name {
        font-size: 1.2em;
      }
  
      .customer-number {
        color: #556655;
        font-size: 1.2em;
      }
    }
  }

  .basic-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid $gray-200;

    .details {
      border-bottom: 1px solid $gray-200;
      line-height: 3.5em;
      padding-left: 1.5em;
      font-weight: 400;
      color: #556655;

      &:first-child {
        padding-top: 1em;
      }

      &:last-child {
        border-bottom: 1px solid transparent;
        padding-bottom: 1em;
      }

      .title {
        font-weight: 500;
        color: $gray-900;
      }

      svg {
        margin-right: 1em;
        color: $tertiary;
        width: 1em;
        height: 1em;
      }

      a {
        color: $blue;
      }
    }

    .card-with-header {
      margin-bottom: 0;
    }
  }
}
