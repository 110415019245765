.agreement-detail {
  .subscription-list-container {
    margin-bottom: 1em;
    border: 1px solid $black;
    height: 30em;
    display: flex;
    flex-flow: column;
  }

  .subscription-title {
    background-color: $pink-100;
    padding: 1em;
  }

  .subscription-list-table {
    flex-grow: 1;
  }

  .full-width-top-header {
    border-radius: 0.6em;
  }
}

.page-header {
  border-bottom: 1px solid $gray-200;
}

.is-archived {
  background-color: $gray-200;
  font-size: 0.8em;
  border-radius: 0.2em;
  font-weight: bold;
  border: 0.1em solid $red-300;

  svg {
    font-size: 1em;
    color: $red-300;
  }
}
