.date-field {
  // React DayPicker Overridden Styles
  .dialog-sheet {
    z-index: 999;
  }

  .rdp {
    background-color: $white;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
    padding: 0.5rem 1rem;

    &-table {
      max-width: 100%;
    }

    &-cell {
      width: 2.75rem;
      height: 4rem;
    }

    // Replace red color with white when today is selected
    .rdp-day_selected.rdp-day_today {
      color: $white !important;
    }

    &-button {
      font-size: 0.8906rem;
      line-height: 1.3594rem;
    }

    &-button:not([disabled]):focus {
      border: 0.0625rem solid $primary;
      background-color: transparent;
    }

    &-button:not([disabled]):hover,
    &-button:not([disabled]):active {
      background-color: $primary;
      color: $white;
      border: 0.0625rem solid $primary;
    }
  }

  .icon-circle {
    color: $gray-400;
    cursor: pointer;
    font-size: $font-size-base;

    top: 50%;
    right: 3%;
    transform: translateY(-50%);
    transition: all 0.25s;

    &:hover {
      @extend .text-danger;
    }
  }
}
