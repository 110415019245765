.menu-user-info {
  display: flex;

  @include max-break-point(mobile-phone) {
    margin: 0;
  }

  .dropdown-toggle {
    // These classes are being over ridden from bootstrap css
    background-color: transparent !important; //to remove background color when clicked on dropdown
    box-shadow: none !important; //to remove shadow effect when clicked on dropdown
    padding-left: 0.7em;

    &:after {
      border-top: 0.3em solid $white;
    }
  }

  .dropdown-menu {
    font-size: 0.9em;
    border-radius: $primary-border-radius;
    inset: 0 auto auto 0;

    .dropdown-item {
      line-height: 2.5em;
      font-weight: 500;
      color: $gray-700;
      padding: 0.5em 1.5em;

      &:active {
        background-color: $gray-100;
      }

      .menu-icon-dropdown {
        font-size: 0.9em;
        width: 1em;
        margin-right: 1.5em;

        svg {
          margin-top: 0.75em;
        }
      }
    }
  }

  .user-name {
    text-overflow: ellipsis;
  }
}

.profile-menu {
  background: none;
  border: none;
  font-weight: 600;
  font-size: 0.9em;

  @include max-break-point(mobile-phone) {
    padding: 0;
  }

  &:hover {
    background: none;
    border: none;
  }

  &:active {
    background: none;
    border: none;
  }
}

//nav minimized
.menu-user-info-minimized {
  margin-top: 1.5em;

  .dropdown-menu {
    /* !important is used in this case to override the initial implementation of the inset property to position the dropdown when menu user info is minimized */
    inset: -4.75rem auto auto 4.5rem !important;
  }

  .user-short-name {
    height: 4em;
    width: 4em;
    padding: 1em 2em;
    border-radius: 50%;
  }

  @include max-break-point(mobile-phone) {
    margin-left: 0.5em;
  }

  .sidebar-dropdown-menu {
    @include min-break-point(tablet) {
      position: fixed;
      top: -4.5em;
      left: 14.5em;
      z-index: 1000;
    }

    @include min-break-point(large-desktop) {
      left: 10.5em;
    }
  }

  .dropdown-menu {
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      z-index: 10;
      border: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      top: 14%;
      border-right: 10px solid $white;
      left: -10px;
    }
  }
}
