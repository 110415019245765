.agreement-information {
  border-right: 1px solid $gray-400;
  display: flex;
  flex-direction: column;

  .avatar-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      position: relative;
      height: 3.5em;
      width: 3.5em;
      border-radius: 50%;
      background-color: $tertiary;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-weight: 600;
      color: $white;
      border: 0.1em solid $gray-200;
      font-size: 3em;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  .subscription-status {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid $gray-400;
    height: 8em;

    .center-border {
      border-right: 1px solid $gray-400;
    }
  }

  .details {
    border-bottom: 1px solid $gray-200;
    line-height: 3.5em;
    padding-left: 1.5em;
    font-weight: 400;
    color: #556655;

    &:first-child {
      padding-top: 1em;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
      padding-bottom: 1em;
    }
  }
}
