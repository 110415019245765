.error-page {
  $primary-color: map-get($brand-color-primary, "primary");

  p {
    text-transform: capitalize;
  }

  .error-icon {
    opacity: 0.4;
  }

  .sub-text {
    font-size: 0.9em;
    color: $primary-color;
  }
}
