.detail-view-wrapper {
  background-color: $white;
  border-radius: $primary-border-radius;
  padding: 1rem;
}

#root {
  height: 100%;
  background-color: $gray-100;
}

.app {
  display: flex;
  height: 100vh;

  @include max-break-point(mobile-phone) {
    flex-direction: column;
  }
}

.app-content {
  display: flex;
  flex: 1;
  padding: 1em 1em;
  overflow: auto;
  flex-direction: column;

  @include max-break-point(mobile-phone) {
    padding: 1em 0;
    padding-top: 0;
    margin-top: 1.5em;
  }
}

.table-container {
  height: 100%;
}

/*global helper classes*/
/*border radius box */
.br-all {
  border-radius: 0.6em;
}

.br-top {
  border-top-left-radius: 0.6em;
  border-top-right-radius: 0.6em;
}

.br-bottom {
  border-bottom-left-radius: 0.6em;
  border-bottom-right-radius: 0.6em;
}

.light-blue-bg {
  background-color: $gray-100;
}

.btn-outline {
  border: 1px solid $primary;
  background-color: transparent;
  color: $primary;
}
