.left-nav-links {
  .active {
    border-left: 5px solid $primary;
    color: $blue-700;
    font-weight: 600;

    .nav-link {
      color: $blue-700;
    }
  }

  .nav-item {
    width: 100%;

    @include max-break-point(tablet) {
      width: inherit;
    }
  }
}

.subscription-group-tab-pane {
  border-left: 1px solid $gray-400;
}
