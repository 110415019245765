.btn {
  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.btn-primary {
  min-width: 7em;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }

  &:disabled {
    color: $white;
    cursor: not-allowed;
  }
  svg {
    margin-right: 0.5em;
  }
}

.btn-outline-primary {
  &:hover,
  &:active,
  &:focus {
    color: $white;
    box-shadow: none;
    background-color: $primary;
  }
}

.btn-outline-secondary {
  background: transparent;
}

.btn-secondary {
  background-color: $gray-300;
  color: $gray-800;
  border: 1px solid $gray-300;

  &.rounded {
    border-radius: $border-radius-full;
  }

  &:hover,
  &:focus {
    background-color: $gray-400;
    border: 1px solid $gray-400;
    color: $gray-800;
  }
}

.input-btn {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 3em;
}

.btn-danger {
  color: $white;

  &:hover,
  &:focus {
    color: $white;
    box-shadow: none;
  }
}

.btn-outline-danger {
  &:hover {
    color: $white;
  }
}

.btn-container {
  @include max-break-point(mobile-phone) {
    flex-direction: column-reverse;
    // to override the equivalent bootstrap class
    align-items: flex-end !important;
  }
}

// .svg-inline--fa.fa-w-18 {
//   display: block;
//   height: 1.1em;
//   width: 1.1em;
// }
