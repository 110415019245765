.cell-phone-suspended {
  .suspended-button {
    background-color: $light-primary-color;
    border: 0.0625em solid $red;
    border-radius: 0.25em;
    color: $red;
  }

  .modal-button {
    @include max-break-point(mobile-phone) {
      width: 100%;
    }
  }

  textarea {
    height: 8em;
  }
}

.suspended {
  background-color: $gray-300;
  border: 0.0625em solid $red;
  border-radius: 0.25em;
}
