.invoice-edit-table {
  border-radius: $primary-border-radius;
  border: 1px solid #f5f5f5;

  thead {
    background-color: $gray-100;
    font-weight: 600;
  }

  input.form-control {
    height: 3em;
    background-color: #f9f9f9;
  }

  textarea {
    width: 11em;
    height: 5em;
  }

  tr {
    height: 3.5em;
  }
}

.table-action-btn {
  border: none;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-select {
  .css-yk16xz-control {
    font-size: 1em;
    border-radius: 0.2em;
    background-color: $white;
    border-color: $gray-400;
    color: $gray-400;
    padding: 0.7rem 1rem;
    font-size: 1em;

    &:hover {
      border-color: $gray-500;
    }

    .css-1okebmr-indicatorSeparator {
      width: 0;
    }
  }

  .css-1pahdxg-control {
    padding: 0.7rem 1rem;
  }
}

.amount-section-edit {
  border-radius: $primary-border-radius;

  .line {
    padding: 0.5em 0.5em;
  }
}

.empty-line-container {
  height: 5em;
}

.description-td-width {
  width: 5em;
}
