.login-container {
  background-color: $gray-100;

  .login-header {
    @include max-break-point(mobile-phone) {
      font-size: $h5-font-size;
      line-height: 1.125rem;
    }
  }

  .login {
    background-color: $white;
    border: 0;
    border-radius: 0.5em;
    width: 100%;
  }

  .brand-logo {
    width: 11rem;
  }
}

.login-information {
  line-height: $h4-line-height;
}

.invalid-text {
  font-size: 0.875‬em;
}

.login-form {
  .icon {
    font-size: 1.6em;
    &.icon-close {
      cursor: pointer;
      font-size: 1.2em;
    }
  }

  .header {
    font-weight: 700;
  }

  .form-check {
    input {
      cursor: pointer;
    }

    label {
      font-size: 0.875em;
      cursor: pointer;
    }
  }
}
