.subscription-list-table {
  border-radius: 0.5em;
  overflow: hidden;

  .thead {
    background-color: $gray-300;
    line-height: 1.8em;
    font-size: $font-size-base;
    color: $body-color;
  }

  .product-list {
    border-bottom: 0.0625em solid $gray-200;
    line-height: 0.2em;
    background-color: $white;
  }

  .total-price {
    background-color: $white;
    line-height: 1.8em;
  }

  tr:nth-child(even) {
    background-color: $white;
  }

  @include max-break-point(mobile-phone) {
    .product-list {
      td:nth-child(1) {
        max-width: 13em;
        font-size: $span-font-size;
        white-space: nowrap;

        span {
          font-size: $font-size-sm;
          /*
          * to override the padding applied by bootstrap class, `!important` property is used
          * since the padding applied to this span has higher specificity than the one applied here  
          */
          padding: 0.2em !important;
        }

        .status {
          font-size: $font-size-sm;
          padding: 0.6em 0.8em !important;
        }
      }

      td:nth-child(2) {
        font-size: $span-font-size;
      }
    }

    .total-price {
      font-size: $span-font-size;
    }
  }
}

@include max-break-point(mobile-phone) {
  .modal-body {
    padding: 0.4em;
  }
}
