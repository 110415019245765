.sim-card-detail {
  .owner-type {
    border-radius: 0.875em;
    padding-top: 0.4em;
    padding-bottom: 0.25em;

    &.employee {
      border: 1px solid $green;
      color: $green;
      background-color: rgba($green, 0.1);
    }

    &.debtor {
      border: 1px solid $light-yellow;
      color: $light-yellow;
      background-color: rgba($light-yellow, 0.1);
    }
  }
}
