.left-nav-links {
  li {
    .active {
      color: $blue-700;
      font-weight: 600;
    }
  }

  svg {
    font-size: 1.2em;
    margin-right: 1em;
  }

  .nav-link {
    color: $gray-500;
    font-weight: 500;
  }

  .dropdown {
    padding: 0;

    p {
      font-size: 0.9em;
      font-weight: 500;
      color: $gray-700;

      svg {
        margin-right: 0.5em;
        font-size: 1em;
      }
    }
  }
}

.user-setting {
  border-bottom: 1px solid $border-color;
  margin-bottom: 2em;
}

.user-setting-description {
  font-weight: 400;
  font-size: 1em;
  color: #556655;
}

.user-setting-coming-soon {
  .nav-link {
    cursor: not-allowed;

    &:hover {
      color: $gray-500;
    }
  }
}

.setting-options {
  .add-options {
    input {
      height: 3.5em;
      margin-bottom: 1em;
    }

    ul {
      padding-left: 0;
    }

    li {
      list-style: none;
    }
  }

  .btn-add {
    background-color: #f9f9f9;
    border: 1px solid #dbdbdb;
    font-weight: 500;
    width: 100%;
    color: #727272;
    border-radius: 0.4em;
    height: 3.8em;
    font-size: 0.9em;
  }

  .option-delete {
    position: absolute;
    right: 0.5em;
    top: 0.35em;
    color: $gray-700;
  }
}

.user-setting-footer {
  display: flex;
  justify-content: flex-end;

  // .btn-submit {
  //   background-color: $primary;
  //   border: none;
  //   padding: 0.75em 2.5em;
  //   font-size: 1em;
  //   border-radius: 0.2em;
  //   font-weight: 600;

  //   &:hover {
  //     background-color: #4e9590;
  //   }
  // }
}
