.record-listing-table-container {
  border-radius: 0 0 0.6em 0.6em;
  background-color: $gray-100;
  overflow: hidden;

  .agreement-status-container {
    border-top: 1px solid $gray-300;
    padding: 1em 0;
  }

  .agreement-archive-button {
    border: 1px solid $pink-200;
    padding: 0.5em 0.5em 0.5em 0;

    &::before {
      color: $white;
      content: attr(archived-count);
      background: $pink-200;
      padding: 0.5em 0.4em;
      margin-right: 0.5em;
    }
  }
}

.agreement-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include min-break-point(large-desktop) {
    flex-direction: row;
    align-items: center;
  }
}

.agreement-status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include min-break-point(tablet) {
    flex-direction: row;
    align-items: center;
  }
}

.agreement-status-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6em;

  @include min-break-point(mobile-phone) {
    justify-content: center;
    margin-bottom: 1.6em;
  }

  @include min-break-point(tablet) {
    flex-direction: row;
    margin-bottom: 1.6em;
  }

  @include min-break-point(large-desktop) {
    flex-direction: row;
    margin-bottom: 0em;
  }
}

.agreement-stats {
  background-color: $gray-100;
  border-radius: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  &.light-green {
    background-color: $green-100;
  }

  &.light-blue {
    background-color: $blue-100;
  }

  &.light-red {
    background-color: $red-100;
  }

  .icon-holder {
    svg {
      font-size: 1em;

      &.dark-green {
        color: $green;
      }

      &.dark-blue {
        color: $blue;
      }

      &.dark-red {
        color: $red;
      }
    }
  }
}

.full-width-top-header {
  border-top-left-radius: 0.6em;
  border-top-right-radius: 0.6em;
}

.search-bar {
  position: relative;
  margin-right: 0;
  width: 100%;
  margin-bottom: 1em;

  .initial-icon {
    position: absolute;
    right: 1em;
    top: 0.9em;
    color: $gray-500;
    z-index: 3;
    font-size: 1.2em;

    @include min-break-point(tablet) {
      right: 0.5em;
    }
  }
}

.record-listing-table {
  flex-grow: 1;
  min-height: 14em;

  @include max-break-point(large-desktop) {
    overflow-x: scroll;
  }

  .ReactVirtualized__Table__headerRow {
    background-color: $gray-100;
    padding: 1.5em 1em 1em;
    font-weight: $font-weight-medium;
    font-size: 1em;
    text-transform: capitalize;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .ReactVirtualized__Table__row {
    border-bottom: 1px solid $gray-100;
    padding: 0 1em;
    font-size: 0.9em;

    &:hover {
      background-color: $gray-100;
      cursor: pointer;
    }
  }

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .row-click-disabled {
    &:hover {
      cursor: default;
    }
  }
}

.empty-table {
  a {
    padding-bottom: 0.01em;
    border-bottom: 0.1em solid $primary-color;

    &:hover {
      border-color: map-get($brand-color-primary, "dark");
    }
  }
}

.info-icon-wrapper {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1em solid $red;
  border-radius: 50%;

  .info-icon {
    font-size: 2.4em;
  }
}
