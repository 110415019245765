.empty-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon-holder {
    height: 3.5em;
    width: 3.5em;
    background-color: $gray-500;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 1.5em;
      color: $white;
    }
  }

  h5 {
    color: $gray-400;
  }
}
