.dropdown-ellipsis-menu {
  .ellipsis-menu-list {
    z-index: 101;
  }

  .dropdown-toggle {
    @include size(3.2em);

    // These classes are being over ridden from bootstrap css
    background-color: $white !important; //to remove background color when clicked on dropdown
    box-shadow: none !important; //to remove shadow effect when clicked on dropdown
    padding: 0;
    border-color: $green;
    color: $green;
    display: inline;

    &:after {
      border: 0;
    }

    .vertical-ellipsis {
      font-size: 1.1rem;
    }
  }

  .dropdown {
    &:hover,
    &:focus,
    &.show {
      // These classes are being over ridden from bootstrap css
      background-color: $white !important;
      color: $green !important;
    }
  }

  .dropdown-menu {
    font-size: 0.9em;
    border-radius: $primary-border-radius;
    /**
    * the default transform property renders the dropdown-menu way below the 
    * ellipsis icon so the transform property is overridden in order to position 
    * the dropdown-menu right below the ellipsis icon
    */
    transform: translate3d(-7.5em, 1em, 0) !important;

    .dropdown-item {
      line-height: 2.5em;
      font-weight: 500;
      color: $gray-700;
      padding: 0.5em 1.5em;

      &:active {
        background-color: $gray-100;
      }

      .menu-icon-dropdown {
        font-size: 0.9em;
        width: 1em;
        margin-right: 1.5em;

        svg {
          margin-top: 0.75em;
        }
      }
    }
  }

  .dropdown-menu-list {
    /**
    * the default transform property renders the dropdown-menu to the very far left 
    * of the ellipsis icon so the transform property is overridden in order to position
    * the dropdown-menu right below the ellipsis icon
    */
    transform: translate3d(-11.9em, 1em, 0) !important;
  }
}
