.stats-icon-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.5‬rem;
  width: 2.4em;
  height: 2.4em;
  font-weight: 600;
  padding: 0.5em 0;
  border-radius: 50%;

  &.gray {
    color: $black;
  }

  &.blue {
    background-color: $blue-100;
    color: $blue-400;
  }

  &.yellow {
    background-color: $yellow-100;
    color: $yellow-400;
  }

  &.primary {
    border: 1px solid $gray-300;
    color: $gray-800;
    background-color: $gray-300;
  }
}

.agreement-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include max-break-point(large-desktop) {
    align-items: baseline;
    flex-direction: column;
  }
}

.agreement-status-content {
  // color: map-get($gray, 'lighter');
  display: flex;
  align-items: center;
  justify-content: center;

  @include max-break-point(mobile-phone) {
    align-items: baseline;
    flex-direction: column;
  }
}

.drop-down-item {
  font-size: 0.8em;
}

.arrow {
  border: solid $black;
  border-width: 0 0.1em 0.1em 0;
  display: inline-block;
  padding: 4px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.resource-dropdown {
  // background-color: map-get($gray, 'lightest');
  border-radius: 24px;

  @include max-break-point(tablet) {
    font-size: 0.83em;
  }
}
