//put all animations related css here
@keyframes dots {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
