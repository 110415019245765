.custom-tooltip {
  cursor: pointer;
  outline: none;

  .tooltip-text {
    @extend .invisible;

    background-color: $black;
    border-radius: 0.5em;
    color: $white;
    font-size: $span-font-size;
    left: 1%;
    line-height: 1.5rem;
    top: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    &::after {
      border-color: transparent transparent $gray-900 transparent;
      border-style: solid;
      border-width: 0.3125em;
      bottom: 100%;
      content: "";
      left: 6%;
      margin-left: -0.3125em;
      position: absolute;
    }
  }

  .terms-and-conditions-tooltip-text {
    transform: translateX(-2%);
    white-space: unset;
    width: 15.625em;

    @include min-break-point(tablet-landscape) {
      width: max-content;
    }

    @include max-break-point(tablet) {
      width: 18.125em;
    }

    @include max-break-point(mobile-phone) {
      transform: translateX(-87%);
      top: 2.0625em;

      &::after {
        top: -100%;
        left: 92%;
      }
    }
  }

  &:hover {
    .tooltip-text {
      @extend .visible;
    }
  }
}

.tool-tip {
  border: unset;
  cursor: pointer;
  outline: unset;

  .tooltip-text {
    background-color: $white;
    border-radius: 0.3125em;
    bottom: 100%;
    color: $gray-700;
    left: 50%;
    min-width: 17.0625em;
    transform: translateX(-50%);

    visibility: hidden;
    z-index: 1;

    &::after {
      @extend .position-absolute;

      border-color: $white transparent transparent transparent;
      border-style: solid;
      border-width: 0.5125em;

      content: "";
      left: 50%;
      margin-left: -0.3125em;
      top: 100%;
    }

    &.disabled-tooltip {
      color: $gray-300;
      font-size: $font-size-base;
      border-radius: 0.625em;
      bottom: unset;
      left: unset;
      min-width: 15em;
      right: 0;
      top: 100%;
      transform: unset;
      z-index: 1;
      background-color: $black;

      &::after {
        border-color: $black transparent transparent transparent;
        bottom: 97%;
        content: "";
        left: unset;
        margin-left: 0;
        margin-right: 0.2em;
        right: 0;
        top: unset;
        transform: rotate(183deg);
      }
    }
  }

  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}

.suspended-tooltip {
  min-width: 19em;

  &-date {
    color: $gray-300;
    font-size: $font-size-base;
    line-height: 1.1em;
    border-bottom: 0.0625em solid $gray-500;
  }

  &-reason {
    color: $gray-300;
    font-size: $font-size-base;
    line-height: 1.1em;
  }

  .tooltip-text {
    &.disabled-tooltip {
      right: unset;
      left: 0;

      &:after {
        right: unset;
        left: 0.2em;
      }
    }
  }
}
