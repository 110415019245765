.detail-ui-header {
  .is-deactivated {
    background-color: $gray-200;
    font-size: 0.8em;
    border-radius: 0.2em;
    font-weight: 600;

    svg {
      font-size: 1em;
      color: $red-300;
    }
  }

  .vertical-ellipsis {
    font-size: 1.1em;
    cursor: pointer;
    padding-inline: 1.4rem;
  }
}
