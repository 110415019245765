.avatar-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .avatar {
    position: relative;
  }

  .icon {
    position: relative;
    height: 3.5em;
    width: 3.5em;
    border-radius: 50%;
    // background-color: $tertiary;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: 600;
    color: $white;
    border: 0.1em solid $gray-200;
    font-size: 3em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .profile-image {
    height: 0.45em;
    width: 0.45em;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    right: -0.05em;
    top: 70%;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 0.2em;
      color: $gray-500;
    }
  }

  .main-info {
    font-size: 1.5em;
  }

  .sub-info {
    color: #556655;
    font-size: 1.2em;
  }
}

.user-detail-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $primary-border-radius;

  .header-container {
    padding: 0.8em 1.5em;
    border-bottom: 1px solid $border-color;
    color: #556655;
  }

  .details {
    border-bottom: 1px solid $gray-200;
    line-height: 3.5em;
    padding-left: 1.5em;
    font-weight: 400;
    color: #373a3c;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .header {
    font-size: 1.1em;
    font-weight: 600;
  }

  .title {
    font-weight: 500;
    color: $gray-900;
  }

  svg {
    margin-right: 1em;
    // color: $tertiary;
    width: 1em;
    height: 1em;
  }

  a {
    color: #4f81ec;
  }
}
