/*
 * Sidebar
 */
.sidebar-section {
  background-color: $sidebar-background-color;
  padding: 0;

  @include max-break-point(mobile-phone) {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1em;
  }

  .side-menu {
    overflow-y: auto;
    overflow-x: hidden;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    background-color: $sidebar-background-color;
    height: 100vh;
    width: 260px;
    transition: width 0.6s ease;

    @include max-break-point(mobile-phone) {
      position: fixed;
      align-items: flex-start;
      justify-content: space-between;
      top: 0;
      left: 0;
      margin-top: 48px;
      width: 100%;
      z-index: 4;
    }

    .menu-icon {
      font-size: 1.1em;
      margin-right: 1em;
      height: 2em;
      width: 2em;
      display: flex;
      justify-content: center;
      align-items: center;

      @include max-break-point(mobile-phone) {
        height: 2em;
        width: 2em;
        background-color: $sidebar-background-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        svg {
          font-size: 0.7em;
        }
      }
    }

    .dashboard-side-nav {
      justify-content: flex-start;
    }
  }

  .side-menu-content {
    background-color: $sidebar-background-color;
    border-radius: 1em;
    align-items: flex-start;
    width: 100%;
    padding-left: 0.2em;

    @include max-break-point(mobile-phone) {
      background-color: transparent;
      align-items: flex-start;
    }

    .nav-item {
      margin-bottom: 0.2em;
      width: 100%;
    }

    .nav-link {
      padding: 0.5em 1em;
      color: $gray-500;
      font-size: 0.9em;
      border-radius: 0.4em;
      font-weight: 600;

      @include max-break-point(mobile-phone) {
        background-color: transparent !important; //to remove full width background
        padding: 0;

        &.active {
          .menu-icon {
            background-color: $primary-color;
          }
        }
      }

      &.active {
        background-color: $primary-color;
        color: $white;
        font-weight: 600;
      }

      svg {
        width: 1.3em;
        height: 1.3em;
      }

      &:hover {
        color: $white;
        background-color: $primary-color;
        transition: all 0.3s;
      }
    }
  }

  .menu-minimizer {
    z-index: 99;
    display: inline-block;
    position: absolute;
    background: $sidebar-background-color;
    border: none;
    border-top-right-radius: 0.2em;
    bottom: 0;
    padding: 0.2em 0.5em 0 0;
    transition: left 0.6s;

    @include max-break-point(mobile-phone) {
      display: none;
    }

    svg {
      color: $white;
      font-size: 1.5em;
    }
  }

  .nav-shadow {
    box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.2);
  }

  //nav minimized
  .sidebar-minimized {
    width: 4.6875rem;

    @include max-break-point(mobile-phone) {
      width: 0 !important;
      padding: 0;
    }

    .minimized-dashboard {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 5em;
      transition: all 0.4s;

      .nav-link {
        width: 3.2em;
        height: 3.2em;
        border-radius: 50%;
        padding: 1em;
        color: $gray-500;
        font-size: 0.9em;
        font-weight: 600;

        &.active {
          color: $white;
          background-color: $primary-color;
          font-weight: 600;
        }

        &:hover {
          color: $white;
          background-color: $primary-color;
          transition: all 0.4s;
        }

        svg {
          width: 1.2em;
          height: 1.2em;
        }

        p {
          display: none;
        }
      }
    }
  }

  .mobile-menu-bar {
    .menu-close {
      border: none;
    }

    @include min-break-point(mobile-phone) {
      display: none;
    }
  }
}

.mobile-menu-open {
  @include max-break-point(mobile-phone) {
    position: absolute;
    left: 0;
  }

  .side-menu {
    @include max-break-point(mobile-phone) {
      margin-top: 0;
      padding-top: 48px;
      transition: padding-top 0.6s;
    }
  }

  .mobile-menu-bar {
    @include max-break-point(mobile-phone) {
      position: fixed;
      top: 15px;
      right: 20px;
      z-index: 100;
    }
  }
}

.side-menu {
  &.sidebar-minimized {
    .dashboard-side-nav {
      justify-content: center;
    }

    .side-menu-content {
      width: auto;
      margin-left: 0.05em;
    }
  }
}

.user-short-name {
  color: $white;
  background-color: $tertiary;
  border-radius: 50%;
  padding: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 4em;
  height: 4em;

  @include max-break-point(mobile-phone) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    height: 4em;
    width: 4em;
  }
}
