.product-item {
  color: $tertiary;
  font-weight: 600;
  border-bottom: 1px solid $border-color;

  .icon-wrapper {
    width: 1.5em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0.4em;

    @include min-break-point(large-desktop) {
      justify-content: center;
      margin-right: 0.8em;
    }
  }

  .tags {
    border-radius: 2em;
    // background-color: map-get($gray, 'lightest');
    margin-right: 0.8em;
    font-size: 0.9em;

    &:last-of-type {
      margin-right: 0em;
    }
  }
}

.shrink {
  max-height: 0;
  transition: max-height 0.35s linear;
  overflow: hidden;
}

.grow {
  max-height: 50em;
  transition: max-height 0.35s linear;
}

.card-head {
  cursor: pointer;
}

.collapse-item {
  height: 2em;
}