.form-group {
  margin-bottom: 1em;
}

.flexo-form,
.subscription-modal {
  background-color: $white;
  box-shadow: $primary-box-shadow;
  border-radius: $primary-border-radius;

  // Override react-select css
  .css-yk16xz-control,
  .css-13cymwt-control,
  .css-t3ipsp-control,
  .css-16xfy0z-control {
    border-radius: 0.2em;
    background-color: $white;
    border-color: $gray-400;
    color: $gray-400;
    padding: 0.7rem 1rem;
    font-size: 1em;

    &:hover {
      border-color: $gray-500;
    }

    .css-1wa3eu0-placeholder {
      // padding-left: 0.5em;
      color: $gray-500;
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }
  }

  // Override react-time-picker css
  .rc-time-picker {
    .rc-time-picker-input {
      padding-left: 0.8em;
    }

    .rc-time-picker-clear {
      margin-top: 0.9em;
      margin-right: 0.1em;
    }
  }

  .flexo-form-footer {
    display: flex;
    justify-content: flex-end;
  }

  // Override react-day-picker
  .DayPickerInput {
    width: 100%;

    input {
      width: 100%;
      padding: 0.875em 1em;
      border: 1px solid $gray-400;
      border-radius: 0.25em;
    }
  }
}

.css-g1d714-ValueContainer {
  padding: 0 !important;
}

.css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}

.search-field-list {
  @extend .mt-1;

  top: 3.45em;
  max-height: 10em;
  border: 0.0625em solid $gray-300;
  z-index: 200;
  border-radius: 0.375em;
  box-shadow: $dropdown-shadow;

  &.loading {
    min-height: 3em;
    border: 0.0625em solid $gray-300;
    color: $gray-700;
  }

  .list-group-item {
    font-size: $font-size-base;
    color: $gray-700;
    border-bottom: 0;

    &:hover,
    &:focus,
    &.active {
      background-color: $primary;
      color: $white;
      cursor: pointer;
    }
  }
}

.search-icon {
  color: $gray-600;
  position: absolute;
  right: 1em;
  bottom: 0;
  height: 100%;
  width: 1.125em;
}
