.subscription-detail {
  h5 {
    color: $gray-700;
  }

  .subscription-info {
    height: 8em;
    border-radius: 0.3em;
    margin-bottom: 0.5em;
    background-color: $gray-100;
  }

  .subscription-info-title {
    padding: 1em;
    background-color: $gray-200;
    min-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subscription-info-content {
    flex: 1;
  }

  .subscription-product-info {
    height: 100%;
  }

  .subscription-product-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .user-short-name {
      height: 4em;
      width: 4em;
    }
  }

  .subscription-product-property {
    background-color: $gray-100;
    height: 100%;
    margin-bottom: 0.5em;

    h5 {
      color: $gray-700;
      min-height: 40%;
      border-bottom: 1px solid $gray-200;
    }

    &:last-child {
      border-right: none;
    }
  }

  .subscription-product-info-title {
    height: 5em;
    min-height: 5em;
  }
}
