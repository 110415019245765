$primary-color: map-get($brand-color-primary, "primary");

.card-with-header {
  margin-bottom: 1.5em;
  border-radius: 0.3em;
  background-color: $white;

  .card-header {
    background-color: #ececec;
    padding: 1.5em;

    &-contents {
      @include max-break-point(mobile-phone) {
        justify-content: space-between;
      }
    }
  }

  .text-area {
    resize: none;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .details {
    border-bottom: 1px solid $gray-200;
    line-height: 3.5em;
    padding-left: 1.5em;
    font-weight: 400;
    color: #556655;
    background-color: transparent;

    &:first-child {
      padding-top: 1em;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
      padding-bottom: 1em;
      border-radius: 0 0 0.3em 0.3em;
    }

    .title {
      font-weight: 500;
      color: $gray-900;
    }

    .hidden-content {
      font-size: $h3-font-size;
    }

    svg {
      margin-right: 1em;
      color: $tertiary;
      width: 1em;
      height: 1em;
    }
  }

  .hide-show-icon {
    cursor: pointer;
  }

  .attached-files {
    @include max-break-point(mobile-phone) {
      justify-content: center;
    }

    .attached-terms-and-condition {
      &:hover {
        color: $primary-color;
        text-decoration: underline;
        text-decoration-color: $primary-color;
      }

      .attached-file-icon {
        font-size: 2em;
      }
    }
  }
}
