.btn-header {
  border: none;
  font-weight: 600;
  font-size: 1em;
  padding: 0.75em 1.5em;
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-download {
  background-color: $pink-200;
  border: none;

  &:hover {
    background-color: #ab6e87;
  }
}

.btn-edit {
  background-color: $primary;
  border: none;

  &:hover {
    background-color: #4e9590;
  }
}

.invoice-detail {
  box-shadow: $primary-box-shadow;
  border-radius: $primary-border-radius;
  background-color: $white;

  .user-short-name {
    width: 4em;
  }

  .invoice-state-col {
    @include max-break-point(tablet) {
      margin-bottom: 1em;
    }
  }
}

.fold {
  position: relative;
}

.fold:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: #c8cdd6 $gray-100;
  transition: all ease 0.5s;
}

.invoice-basic-details {
  font-weight: 600;

  .line {
    border-bottom: 1px solid $border-color;
    line-height: 3em;

    &:last-child {
      border-bottom: none;
    }
  }
}

.invoice-label {
  color: $gray-500;
  text-transform: uppercase;
}

.invoice-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $gray-700;
  height: 3em;
  color: $white;
  font-weight: 600;
}

.invoice-table {
  border-radius: $primary-border-radius;
  background-color: $white;
  border: 1px solid #f5f5f5;
  box-shadow: 0 0.075rem 0.05rem rgba(0, 0, 0, 0.075);

  thead {
    background-color: $gray-100;
    height: 3.5em;
  }

  tr {
    height: 3.5em;

    &:hover {
      background-color: $gray-100;
    }
  }
}

.amount-section {
  font-weight: 600;

  .border-line {
    border: 1px solid #f5f5f5;
  }

  .line {
    border-bottom: 1px solid $border-color;
    line-height: 3em;

    &:hover {
      background-color: $gray-100;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.invoice-detail-header-row {
  @include max-break-point(tablet) {
    flex-flow: column-reverse;
  }
}
