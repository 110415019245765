html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  height: 100%;
  font: 112.5%/1.45em georgia, serif;
  box-sizing: border-box;
}

body {
  word-wrap: break-word;
  font-kerning: normal;
  overflow: hidden;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

section {
  @include padding-top-bottom(3em);

  @include max-break-point(mobile-phone) {
    @include padding-top-bottom(1em);
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-heading;
}

label {
  font-weight: $font-weight-medium;
}

.bg-none {
  background: transparent !important;
}

a {
  text-decoration: none;
}

.invalid-text {
  font-size: 0.75rem;
}

.text-gray {
  color: $gray-600 !important;
}

.nav-link {
  cursor: pointer;
}

.text-tertiary {
  color: $tertiary !important;
}

.bg-tertiary {
  background-color: $tertiary !important;
}

.h-20em {
  height: 20em;
}

.h-30em {
  height: 30em;
}

.min-w-70-percent {
  min-width: 70%;
}

#webpack-dev-server-client-overlay {
  display: none;
}
