.subscription-modal {
  max-width: 85%;

  @include max-break-point(mobile-phone) {
    max-width: 95%;
  }
}

.subscription-detail-modal {
  max-width: 85%;
}

.subscription-edit-modal {
  @include min-break-point(mobile-phone) {
    min-width: 41rem;
  }
}
