.forbidden {
  h3,
  h4 {
    color: rgba($color: $gray-800, $alpha: 0.8);
  }

  .description {
    color: rgba($color: $gray-800, $alpha: 0.8);
    font-family: $font-family-heading;
    font-size: $font-size-base;
  }
}
