.dashboard-items-container {
  justify-content: center;
}

.dashboard-item {
  height: 24.5em;
  margin-bottom: 2em;
  box-shadow: $primary-box-shadow;
  border-radius: $primary-border-radius;
  background-color: $white;
  display: flex;
  flex-direction: column;
}

.item-title {
  color: $gray-900;
  text-align: left;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  border-bottom: 1px solid $border-color;
  font-weight: $font-weight-medium;

  .icon-title {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-holder {
      height: 2em;
      width: 2em;
      border-radius: 50%;
      background-color: #e3f2f1;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      margin-right: 0.8em;
    }

    svg {
      color: $primary;
    }
  }
}

.item-content {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    margin-bottom: 0.4em;
  }

  .user-stats {
    display: flex;
    justify-content: space-evenly;
    align-content: flex-end;
    height: 4em;
    margin: 1.5em 0;

    small {
      color: $gray-500;
      margin-bottom: 0.5em;
      padding-top: 0.15em;
    }

    h3 {
      font-weight: bold;
    }

    .base-stats {
      display: flex;
      flex-direction: column;
    }
  }

  .new-users {
    border-bottom: 1px solid $border-color;
    margin: 0.5em 0;
    flex: 1;
    overflow: auto;

    .user {
      padding: 0.5em 1em 0.3em;

      &:hover {
        background-color: #f5f5f5;
      }

      h5 {
        margin-bottom: 0.3em;
        font-weight: 600;
      }

      p {
        color: $gray-500;
        font-size: 0.8em;
      }
    }
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    align-items: center;
    padding: 1em;

    &:hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      border-bottom: none;
    }

    span {
      font-weight: $font-weight-normal;
      font-size: 0.9em;

      svg {
        margin-right: 0.5em;
        height: 1em;
        width: 1em;
      }
    }
  }

  .single-stats {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    .circle-icon {
      background-color: #e3f2f1;
      margin-top: 2em;
      height: 5em;
      width: 5em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-bottom: 1.5em;

      svg {
        font-size: 2em;
      }
    }

    h1 {
      margin-bottom: 0;
      font-size: 4em;
    }

    small {
      margin-bottom: 1.5em;
      color: $gray-500;
      font-weight: 500;
    }

    p {
      font-weight: 500;
      font-size: 0.9em;
    }
  }
}
