.tab-pane {
  label {
    font-size: 1em;
    margin-bottom: 0.1em;
  }

  .DayPickerInput {
    input {
      font-size: 1em;
      height: 3em;
      background-color: $white;
      border-color: $gray-200;
      border-radius: 0.3em;
      line-height: 1.5em;
      padding: 0.375rem 0.75rem !important;
    }
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {
    background-color: #f9f9f9;

    .css-1hwfws3 {
      font-size: 1em;
      height: 3em;
      line-height: 1.5em;
      padding: 0.375rem 0.75rem;
    }
  }

  .css-1nmdiq5-menu {
    width: 96%;
  }
}

.subscription-lines-section {
  .table-responsive {
    overflow: visible;
  }
}

.subscription-list-table {
  thead {
    th {
      font-size: 0.9em;
    }
  }
}
