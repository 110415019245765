.submenu-minimized {
  .submenu-minimized-list {
    margin-top: -6.8em;
    left: 10.8em;
    z-index: 101;

    &.telecom {
      left: 16.2em;
      margin-top: -5.8em;
    }
  }

  .dropdown-toggle {
    @include size(3.2em);

    // These classes are being over ridden from bootstrap css
    background-color: transparent !important; //to remove background color when clicked on dropdown
    box-shadow: none !important; //to remove shadow effect when clicked on dropdown
    padding: 0;
    color: $gray-500;

    &:after {
      border: 0;
    }

    &:hover,
    &.active-path {
      color: $white;
      // These classes are being over ridden from bootstrap css
      background-color: $primary-color !important;
    }
  }

  .dropdown {
    &:hover,
    &:focus,
    &.show {
      .dropdown-toggle {
        // These classes are being over ridden from bootstrap css
        background-color: $primary-color !important;
      }
    }
  }

  .dropdown-menu {
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      z-index: 10;
      border: 0;
      border-top: 0.625em solid transparent;
      border-bottom: 0.625em solid transparent;
      top: 8%;
      border-right: 0.625em solid $white;
      left: -0.625em;
    }
  }

  .dropdown-menu {
    font-size: 0.9em;
    border-radius: $primary-border-radius;

    .dropdown-item {
      line-height: 2.5em;
      font-weight: 500;
      color: $gray-700;
      padding: 0.5em 1.5em;

      &:active {
        background-color: $gray-100;
      }

      .menu-icon-dropdown {
        font-size: 0.9em;
        width: 1em;
        margin-right: 1.5em;

        svg {
          margin-top: 0.75em;
        }
      }
    }
  }
}

.sub-menu {
  background-color: $sidebar-background-color;
  .accordion-body {
    list-style: unset;
  }

  .accordion-button {
    padding: 0.5em 1em;
    font-size: 0.9em;
    background-color: $primary-color;
    color: $white;
    font-weight: 600;
    border-radius: 0.4em;

    svg {
      width: 1.3em;
      height: 1.3em;
    }

    &:hover,
    &:focus,
    &.active-path {
      // These classes are being over ridden from bootstrap css
      color: $white !important;
      background-color: $primary-color !important;
      transition: all 0.3s;
      box-shadow: unset;
      outline: unset;

      &:after {
        filter: brightness(0) invert(1);
      }
    }

    &:not(.collapsed) {
      color: $white;
      background-color: $primary-color;
      box-shadow: unset;

      &:after {
        filter: brightness(0) invert(1);
      }
    }

    &:after {
      filter: invert(84%) sepia(11%) saturate(204%) hue-rotate(169deg)
        brightness(85%) contrast(87%);
    }

    &.collapsed {
      background-color: unset;
      color: $gray-500;

      &:hover,
      &:focus {
        color: $white;
        background-color: $primary-color;
        transition: all 0.3s;
        box-shadow: unset;
        outline: unset;

        &:after {
          filter: invert(1);
        }
      }
    }
  }

  .accordion-item {
    background-color: $sidebar-background-color;
  }

  .accordion-collapse {
    &.show {
      background-color: $secondary-light;
    }
  }
}
