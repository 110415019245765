.icon-circle {
  color: $gray-400;
  cursor: pointer;
  font-size: $font-size-base;

  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  transition: all 0.25s;

  &:hover {
    @extend .text-danger;
  }
}
