.unauthorized-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 6em;
    color: $primary;
  }

  h2 {
    color: #0a1a3e;
  }

  p {
    color: $medium-text-color;
  }
}
