/*tab design */

.custom-nav-tab {
  background-color: $white;
  border-top-left-radius: 0.6em;
  border-top-right-radius: 0.6em;
  border-bottom: 1px solid $gray-100;

  .nav-item {
    background-color: $white;
    border-radius: 0;
    border-bottom: 1px solid $gray-100;
    border-top: 5px solid $white;
    border-top-left-radius: 0;
    color: $gray-500;

    &:first-child {
      border-top-left-radius: 0.6em;
    }

    &.active {
      border-top: 5px solid $primary;
      color: $blue-700;
    }
  }

  .nav-link {
    border-radius: 0;
    border: none;
    font-size: 1em;
    font-weight: $font-weight-medium;
  }
}

.vertical-list {
  border: none;

  .list-group-item {
    border: none;
    border-bottom: 1px solid $gray-100;
    padding: 1.25em 2em;
    font-weight: 600;
    color: $gray-500;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    &:hover {
      color: $primary;
    }

    svg {
      height: 1em;
      width: 1em;
    }
  }

  .active-list {
    color: $gray-900;
    font-weight: 600;
  }
}

.subscription-detail-tab-content {
  border-bottom-left-radius: 0.6em;
  border-bottom-right-radius: 0.6em;
}

.subscription-list-table {
  background-color: $white;

  thead {
    font-size: 0.9em;

    tr {
      background-color: $gray-100;
    }
  }

  tr {
    padding-top: 4em;

    &:nth-child(even) {
      background-color: $gray-100;
    }
  }

  td {
    p {
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $gray-100;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .subscription-properties {
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
