.file-import-section {
  li {
    list-style-type: square;
  }
}

.requested-number-list-table {
  table {
    caption-side: top;
  }

  .DayPickerInput-Overlay {
    position: relative;
    display: table;
  }

  .w-30em {
    width: 30em;
  }
}
