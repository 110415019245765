@font-face {
  font-family: 'Neutrif';
  src: url('../../fonts/NeutrifPro/NeutrifPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neutrif';
  src: url('../../fonts/NeutrifPro/NeutrifPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin';
  src: url('../../fonts/Acumin/Acumin-RPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin';
  src: url('../../fonts/Acumin/Acumin-BdPro.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
