.tab-route-container {
  background-color: $white;
  border-radius: 0.6em 0.6em 0 0;

  .tab-route-header {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid $gray-100;

    a {
      font-size: 1em;
      font-weight: $font-weight-medium;
      background-color: $white;
      border-radius: 0;
      border-top: 5px solid $white;
      border-top-left-radius: 0;
      color: $secondary;
      padding: 1.5em;

      &:first-child {
        border-top-left-radius: 0.6em;
      }

      &.active {
        border-top: 5px solid $primary;
        color: $blue-700;
      }
    }
  }

  .tab-route-content {
    flex: 1;
  }
}
