.skeleton-table {
  th {
    border-top: 1px solid transparent;
    color: transparent;
  }

  td {
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;
  }
}
