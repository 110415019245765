.modal {
  .close {
    border: none;
    color: $gray-600;
    background: none;

    span {
      font-weight: $font-weight-bold;
    }
  }

  .preview-file-modal {
    &-body {
      height: 38em;
      padding: 0;
    }

    @include min-break-point(mobile-phone) {
      min-width: 34em;
    }
  }
}

.session-expired-overlay {
  border-radius: 0;
  cursor: default;
  inset: 0;
  z-index: 98;

  @supports not (backdrop-filter: blur(4.0625em)) {
    background-color: rgba($white, 0.9);
  }

  @supports (backdrop-filter: blur(4.0625em)) {
    backdrop-filter: blur(4.0625em);
    background: $light-grey-overlay;
  }

  &.show {
    opacity: 0.8;
  }
}
