.pill {
  &.status {
    border-radius: 0.875em;
    height: 1.5em;

    &.bg-green {
      border: 0.0625em solid $green;
      color: $green;
      background-color: rgba($green, 0.1);
    }

    &.bg-red {
      border: 0.0625em solid $red;
      color: $red;
      background-color: rgba($red, 0.1);
    }

    &.bg-yellow {
      border: 0.0625em solid $light-yellow;
      color: $yellow;
      background-color: rgba($light-yellow, 0.1);
    }
  }
}
