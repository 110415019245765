.quotation-subscription-list {
  &-header {
    background-color: $mild-gray;

    .header-cell {
      font-size: $font-size-base;

      @include max-break-point(large-desktop) {
        // to override the padding applied by the bootstrap class
        padding: 0.725em 1em calc(0.725em - 0.25em) !important;
      }
    }
  }

  .subscriptions {
    .subscription-type {
      background-color: $gray-200;
    }
  }
}

.other-products-table {
  &-headers {
    background-color: $mild-gray;
    border: 0.0625em solid $mild-gray;
  }

  &-row {
    border: 0.0625em solid $mild-gray;
    min-height: 5em;

    .editable-product-name-with-label {
      @include max-break-point(tablet-landscape) {
        flex-direction: column-reverse;
        gap: 0.25em;
        // to override the equivalent bootstrap class behaviour
        align-items: flex-start !important;
      }
    }
  }
}
