// =========================================================
// ==================== Colors =============================
// =========================================================

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #989898;
$gray-700: #535353;
$gray-800: #272727;
$gray-900: #050721;
$black: #000;
$mild-gray: #ececec;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #e35d6a;
$orange: #f68e5f;
$yellow: #ffc107;
$green: #37b772;
$teal: #20c997;
$cyan: #0dcaf0;
$light-yellow: #f8dc5e;

$primary: $green;
$primary-color: $primary;
$secondary: #0a1a3e;
$secondary-light: #0c1e4e;

$tertiary: #4281a4;

$blue-700: #192748;
$body-color: #212529;
$placeholder-color: #999999;
$blank-input-color: #d0d0d0;
$successColor: $green;
$th-background: $gray-400;

$sidebar-background-color: $secondary;
$active-color: $primary;

$medium-text-color: $gray-700;
$light-grey-overlay: rgba(151, 146, 146, 0.2);
$light-primary-color: rgba(227, 93, 106, 0.05);
$primary-box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1), -0.125em 0 0 $primary;
$dropdown-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.12);

$brand-color-primary: (
  darkest: #3b7974,
  darker: #448b85,
  dark: #17a663,
  primary: #37b772,
  light: #2adc89,
  lighter: #76beb8,
  lightest: #88c6c1,
);

$social-icon-color: (
  facebook: #4267b2,
  twitter: #38a1f3,
  google: #db4437,
  linked-in: #0077b5,
);

// =========================================================
// ===================== Fonts =============================
// =========================================================

$font-size-base: 1rem;
$font-size-sm: 0.8rem;
$font-size-lg: $font-size-base;
$font-family-base: Neutrif, "sans-serif";
$font-family-heading: Acumin, "sans-serif";
$h1-font-size: 3.5rem;
$h2-font-size: 2.5rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: $font-size-base;
$span-font-size: 0.875rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$headings-font-weight: $font-weight-bold;

// =========================================================
// ================= Buttons and Forms =====================
// =========================================================

$input-btn-padding-y: 0.875em;
$input-btn-padding-x: 1em;

$border-radius-full: 100em;

$input-btn-padding-y-lg: 0.875em;
$input-btn-padding-x-lg: 1em;

$btn-disabled-opacity: 0.4;

// $input-btn-focus-color: $primary;

// =========================================================
// ==================== Shadow =============================
// =========================================================

$primary-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

// =========================================================
// ==================== Border =============================
// =========================================================

$primary-border-radius: 0.4em;
$border-color: $gray-200;

// $primary-color: map-get($brand-color-primary, 'primary');
// $link-hover-color: $primary-color;

$background-color: $gray-300;

// scss-docs-start form-label-variables
$form-label-margin-bottom: 1rem;
$form-label-font-weight: $font-weight-medium;

// =========================================================
// ==================== Badges =============================
// =========================================================

// scss-docs-start badge-variables
$badge-font-size: 0.75em;
$badge-font-weight: $font-weight-base;
$badge-color: $white;
$badge-padding-y: 0.35em;
$badge-padding-x: 0.65em;
$badge-border-radius: $border-radius-full;

// =========================================================
// ======================= Nav =============================
// =========================================================

$nav-link-transition: none;

// line height
$h4-line-height: 1.93rem;
$h5-line-height: 1.5rem;
